import { renderToStaticMarkup } from "react-dom/server";
import { nanoid } from "nanoid";
import { RAGAgent } from "../../api";

export type Citation = {
  id: string;
  text: string;
};

type HtmlParsedAnswer = {
  answerHtml: string;
  citations: Citation[];
};

function annotateSectionsForLegislation(selectedAgent: RAGAgent, content: string): string {
  if (selectedAgent !== RAGAgent.Legislation) return content;

  // Regex to match "Section" followed by a number or line starting with Section (example - **99)
  const sectionRegex = /(Sections? (\d+[a-zA-Z]?))|\*\*(\d+[a-zA-Z]?)\s(.+?)\*\*/gi;

  const createAnnotatedSection = (
    match: string,
    sectionWithText?: string,
    sectionNumber?: string,
    boldSectionNumber?: string,
    boldSectionText?: string
  ): string => {
    const legislationAct = "Act 2010 - Section";

    if (sectionWithText) {
      return `${sectionWithText}[${legislationAct} ${sectionNumber}]`;
    } else if (boldSectionNumber && boldSectionText) {
      return `**${boldSectionNumber} ${boldSectionText}**[${legislationAct} ${boldSectionNumber}]`;
    }

    return match;
  };

  return content.replace(sectionRegex, createAnnotatedSection);
}

export function parseAnswerToHtml(answer: string, isStreaming: boolean, selected_agent: RAGAgent): HtmlParsedAnswer {
  const citations: Citation[] = [];

  let parsedAnswer = annotateSectionsForLegislation(selected_agent, answer.trim());

  // Omit a citation that is still being typed during streaming
  if (isStreaming) {
    let lastIndex = parsedAnswer.length;
    for (let i = parsedAnswer.length - 1; i >= 0; i--) {
      if (parsedAnswer[i] === "]") {
        break;
      } else if (parsedAnswer[i] === "[") {
        lastIndex = i;
        break;
      }
    }
    const truncatedAnswer = parsedAnswer.substring(0, lastIndex);
    parsedAnswer = truncatedAnswer;
  }

  const parts = parsedAnswer.split(/\[([^\]]+)\]/g);
  const fragments: string[] = parts
    .map((part, mapIndex) => {
      if (mapIndex % 2 === 0) {
        return part.replaceAll("(url)", "").replace(/```/g, "").replace(/\n/g, "<br />").replace(/ {2}/g, "&nbsp;&nbsp;");
      } else {
        const cleanParts = part.includes(",") ? part.split(",") : [part];

        // Initialize an array to hold fragments for each part if split by commas
        const citationFragments: string[] = [];

        cleanParts.forEach((cleanPart, partIndex) => {
          const trimmedPart = cleanPart.replace(/^\(/, "").replace(/\)$/, "").replace(":", "").replaceAll("^", "").trimStart();

          if (!trimmedPart || trimmedPart.length <= 3) {
            return; // Skip empty or invalid citations
          }

          let citationId: string | null = null;
          const existingCitationIndex = citations.findIndex(citation => citation.text === trimmedPart);

          if (existingCitationIndex === -1) {
            citationId = nanoid();
            citations.push({ id: citationId, text: trimmedPart });
          } else {
            citationId = citations[existingCitationIndex].id; // Use the existing citation's ID
          }

          citationFragments.push(
            renderToStaticMarkup(
              <a className="supContainer" title={trimmedPart} data-citation-index={citationId} key={`${mapIndex}-${partIndex}`}>
                <sup>{existingCitationIndex !== -1 ? existingCitationIndex + 1 : citations.length}</sup>
              </a>
            )
          );
        });

        return citationFragments.join(""); // Combine all the citation fragments into a single string
      }
    })
    .filter((fragment): fragment is string => fragment !== null); // Filter out null values

  return {
    answerHtml: fragments.join(""),
    citations
  };
}
