import { nanoid } from "nanoid";
import { ConversationEntry, FeedbackData, FeedbackStorage } from "./api";

const ENV = `${import.meta.env.VITE_ENV}` || "dev";
const SESSION_KEY = `${ENV}-coai-session-id`;
const CONV_KEY = `${ENV}-coai-conversations`;
const FEEDBACK_STORAGE_KEY = `${ENV}-coai-feedbacks`;

const start = () => !getId() && localStorage.setItem(SESSION_KEY, nanoid());
const getId = (): string => localStorage.getItem(SESSION_KEY) ?? "";

const saveConversationEntries = (c: ConversationEntry[]): void => localStorage.setItem(CONV_KEY, JSON.stringify(c));

const getConversationEntries = (): ConversationEntry[] => {
  const c = localStorage.getItem(CONV_KEY);
  try {
    return c ? JSON.parse(c) : [];
  } catch {
    return [];
  }
};

const chatFeedbackStorage = (): FeedbackStorage => {
  const s = localStorage.getItem(FEEDBACK_STORAGE_KEY);
  try {
    return s ? JSON.parse(s) : {};
  } catch {
    return {};
  }
};

const getChatFeedbackData = (bot_resp_id: string): FeedbackData => {
  const s = chatFeedbackStorage();
  return s[bot_resp_id];
};

const updateChatFeedback = (bot_resp_id: string, value: FeedbackData): void => {
  const s = chatFeedbackStorage();
  s[bot_resp_id] = { ...s[bot_resp_id], ...value };
  localStorage.setItem(FEEDBACK_STORAGE_KEY, JSON.stringify(s));
};

const clear = (): void => {
  localStorage.removeItem(SESSION_KEY);
  localStorage.removeItem(CONV_KEY);
  localStorage.removeItem(FEEDBACK_STORAGE_KEY);
};

export default {
  start,
  getId,
  saveConversationEntries,
  getConversationEntries,
  getChatFeedbackData,
  updateChatFeedback,
  clear
};
