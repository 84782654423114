const IconInaccurate = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.7"
      d="M5.25 18L0 12.75V5.25L5.25 0H12.75L18 5.25V12.75L12.75 18H5.25ZM6.15 13.25L9 10.4L11.85 13.25L13.25 11.85L10.4 9L13.25 6.15L11.85 4.75L9 7.6L6.15 4.75L4.75 6.15L7.6 9L4.75 11.85L6.15 13.25ZM6.1 16H11.9L16 11.9V6.1L11.9 2H6.1L2 6.1V11.9L6.1 16Z"
      fill="#22272B"
    />
  </svg>
);

export default IconInaccurate;
