import { Button } from "@fluentui/react-components";
import NewChat from "../../assets/newChat";
import React from "react";
import styles from "./ClearChatButton.module.css";

interface Props {
  className?: string;
  onClick: () => void;
  disabled?: boolean;
  style?: React.CSSProperties;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  isInside: boolean;
}

export const ClearChatButton = ({ className, style, disabled, onClick, onMouseEnter, onMouseLeave, isInside }: Props) => {
  const color = isInside ? "#002664" : "white";
  return (
    <div className={styles.button} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Button onClick={onClick} disabled={disabled} className={`${styles.container} ${className ?? ""}`} style={style}>
        {"New chat"}
        <div className={styles.newChatIcon}>
          <NewChat color={color} />
        </div>
      </Button>
    </div>
  );
};
