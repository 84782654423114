const Avatar = () => (
<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="13" cy="13" r="13" fill="#002664" />
    <path
      d="M13 7C14.1 7 15 7.9 15 9C15 10.1 14.1 11 13 11C11.9 11 11 10.1 11 9C11 7.9 11.9 7 13 7ZM13 16C15.7 16 18.8 17.29 19 18V19H7V18.01C7.2 17.29 10.3 16 13 16ZM13 5C10.79 5 9 6.79 9 9C9 11.21 10.79 13 13 13C15.21 13 17 11.21 17 9C17 6.79 15.21 5 13 5ZM13 14C10.33 14 5 15.34 5 18V21H21V18C21 15.34 15.67 14 13 14Z"
      fill="white"
    />
  </svg>
);

export default Avatar;
