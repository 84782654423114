import { Switch, Route } from "react-router-dom";
import Chat from "../chat/Chat";

import styles from "./Layout.module.css";

const Layout = () => {
  return (
    <div className={styles.layout}>
       <Switch>
        <Route exact path="/home" component={Chat} />
      </Switch>
    </div>
  );
};

export default Layout;
