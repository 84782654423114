export const enum RetrievalMode {
  Hybrid = "hybrid",
  Vectors = "vectors",
  Text = "text"
}

export type ChatAppRequestOverrides = {
  retrieval_mode?: RetrievalMode;
  semantic_ranker?: boolean;
  semantic_captions?: boolean;
  exclude_category?: string;
  top?: number;
  temperature?: number;
  prompt_template?: string;
  prompt_template_prefix?: string;
  prompt_template_suffix?: string;
  suggest_followup_questions?: boolean;
  use_oid_security_filter?: boolean;
  use_groups_security_filter?: boolean;
};

export type ResponseMessage = {
  content: string;
  role: string;
};

export type ResponseContext = {
  thoughts: string | null;
  data_points: string[];
  followup_questions: string[] | null;
};

export type ResponseChoice = {
  id: string;
  index: number;
  message: ResponseMessage;
  context: ResponseContext;
  session_state: any;
  sessionId: string;
  feedback: string;
};

export type ChatAppResponseOrError = {
  choices?: ResponseChoice[];
  error?: string;
};

export type ChatAppResponse = {
  choices: ResponseChoice[];
  selected_agent: RAGAgent;
  recommendor_response: string;
};

export type ConversationEntry = [user: string, response?: ChatAppResponse];

export type ChatAppRequestContext = {
  overrides?: ChatAppRequestOverrides;
};

export type ChatAppRequest = {
  sessionId?: string;
  messages: ResponseMessage[];
  context?: ChatAppRequestContext;
  stream?: boolean;
  session_state: any;
};

export enum FeedbackBtnType {
  Inaccurate = "inaccurate",
  MissingInfo = "missing_info",
  OutOfDate = "out_of_date"
}

export type FeedbackData = {
  type?: FeedbackBtnType;
  reason?: string;
};

export type FeedbackStorage = Record<string, FeedbackData>;

export const isFeedbackBtnType = (value: any): value is FeedbackBtnType => {
  return Object.values(FeedbackBtnType).includes(value);
};

export const enum RAGAgent {
  Legislation = "legislation",
  FairTrading = "fair_trading",
  USU = "usu_knowledge_base"
}
