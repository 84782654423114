const SubmitFeedback = () => (
  <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="34" height="34" rx="4" fill="#002664" />
    <path opacity="0.01" fill-rule="evenodd" clip-rule="evenodd" d="M9 9H25V25H9V9Z" fill="#CD0000" />
    <path
      d="M10.1512 23.93L24.5005 17.759C25.1665 17.4702 25.1665 16.5298 24.5005 16.241L10.1512 10.07C9.60851 9.83078 9.00822 10.235 9.00822 10.8208L9 14.624C9 15.0365 9.30425 15.3913 9.71541 15.4408L21.3346 17L9.71541 18.551C9.30425 18.6087 9 18.9635 9 19.376L9.00822 23.1792C9.00822 23.765 9.60851 24.1692 10.1512 23.93Z"
      fill="white"
    />
    <path opacity="0.01" fill-rule="evenodd" clip-rule="evenodd" d="M9 9H25V25H9V9Z" fill="#CD0000" />
    <path
      d="M10.1512 23.93L24.5005 17.759C25.1665 17.4702 25.1665 16.5298 24.5005 16.241L10.1512 10.07C9.60851 9.83078 9.00822 10.235 9.00822 10.8208L9 14.624C9 15.0365 9.30425 15.3913 9.71541 15.4408L21.3346 17L9.71541 18.551C9.30425 18.6087 9 18.9635 9 19.376L9.00822 23.1792C9.00822 23.765 9.60851 24.1692 10.1512 23.93Z"
      fill="white"
    />
  </svg>
);

export default SubmitFeedback;
