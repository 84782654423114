const NewChat = ({ color }: { color: string }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.99998 13.0058H5.99998C6.26579 13.0073 6.52127 12.903 6.70998 12.7158L14.71 4.7158C14.8993 4.52803 15.0058 4.27244 15.0058 4.0058C15.0058 3.73916 14.8993 3.48356 14.71 3.2958L11.71 0.295798C11.5222 0.106486 11.2666 0 11 0C10.7333 0 10.4778 0.106486 10.29 0.295798L2.28998 8.2958C2.1028 8.48452 1.99845 8.74 1.99998 9.0058V12.0058C1.99998 12.5581 2.4477 13.0058 2.99998 13.0058ZM10.9999 2.41577L12.5899 4.00577L10.9999 5.59577L9.40986 4.00577L10.9999 2.41577ZM7.99998 5.4158L3.99998 9.4158V11.0058H5.58998L9.58998 7.0058L7.99998 5.4158Z"
        fill={color}
      />
      <path
        d="M15 14.0058H1C0.447715 14.0058 0 14.4535 0 15.0058C0 15.5581 0.447715 16.0058 1 16.0058H15C15.5523 16.0058 16 15.5581 16 15.0058C16 14.4535 15.5523 14.0058 15 14.0058Z"
        fill={color}
      />
    </svg>
  );
};

export default NewChat;
