import { useState, useRef, useEffect } from "react";
import { SectionPage } from "@snsw-gel/react";
import { ITextField, Stack, TextField } from "@fluentui/react";
import styled from "styled-components";
import styles from "./Answer.module.css";
import CloseInput from "../../assets/closeInput";
import IconInaccurate from "../../assets/iconInaccurate";
import IconOutOfDate from "../../assets/iconOutOfDate";
import IconMissingInfo from "../../assets/iconMissingInfo";
import IconFeedback from "../../assets/iconFeedback";
import SubmitedIcon from "../../assets/submitedIcon";
import SubmitFeedback from "../../assets/submitFeedback";
import { FeedbackData } from "../../api";

type FeedbackProps = {
  save: (feedback: string) => Promise<void>;
  getFeedbackData: () => FeedbackData;
};

export const Feedback = ({ save, getFeedbackData }: FeedbackProps) => {
  const [clickedItem, setClickedItem] = useState<string>("");
  const [isReasonPopupOpen, setReasonPopup] = useState<boolean>(false);
  const [feedbackData, setFeedbackData] = useState<FeedbackData>(getFeedbackData());
  const [textFieldValue, setTextFieldValue] = useState<string>("");

  const inputRef = useRef<ITextField | null>(null);

  useEffect(() => {
    if (isReasonPopupOpen) {
      inputRef.current?.focus()
    }
  }, [isReasonPopupOpen])

  const isFeedbackReasonSubmitted = !!feedbackData?.reason;

  const feedbackItems = [
    { type: "inaccurate", icon: <IconInaccurate />, text: "Inaccurate" },
    { type: "out_of_date", icon: <IconOutOfDate />, text: "Out of date" },
    { type: "missing_info", icon: <IconMissingInfo />, text: "Missing info" },
    { type: "user_feedback", icon: <IconFeedback />, text: "Provide Feedback" }
  ];

  const onFeedbackItemClick = (field: string) => {
    setClickedItem(field);
    if (field === "user_feedback") {
      setReasonPopup(true);
    } else {
      save(field).then(() => setFeedbackData(getFeedbackData()));
    }
  };

  const onInputChange = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    setTextFieldValue(newValue ? newValue : "");
  };

  const onKeyChange = (ev: React.KeyboardEvent<Element>) => {
    if (ev.key === "Enter" && !ev.shiftKey) {
      ev.preventDefault();
      submitFeedback();
    }
  };

  const submitFeedback = () => {
    if (textFieldValue && textFieldValue.length > 1) {
      save(textFieldValue).then(() => {
        setReasonPopup(false);
        setFeedbackData(getFeedbackData());
      });
    }
  };

  const onCloseFeedback = () => {
    setTextFieldValue("")
    setReasonPopup(false)
  }

  const isFeedbackItemExists = (field: string) => {
    if (field === "user_feedback") return !!feedbackData?.reason;
    return feedbackData?.type === field;
  };

  return (
    <Stack.Item>
      <Stack horizontal wrap tokens={{ childrenGap: 15, padding: 30 }}>
        {feedbackItems.map(item => (
          <div
            key={item.type}
            className={styles.feedbackItem}
            style={clickedItem === item.type ? { textDecorationLine: "none" } : {}}
            onClick={() => onFeedbackItemClick(item.type)}
          >
            <div className={styles.feedbackContent}>
              {isFeedbackItemExists(item.type) ? (
                <>
                  <SubmitedIcon /> <p style={{ color: "#008A07", margin: "10px" }}>{item.text}</p>
                </>
              ) : (
                <>
                  {item.icon} <p style={{ margin: "10px" }}>{item.text}</p>
                </>
              )}
            </div>
          </div>
        ))}
      </Stack>
      {isReasonPopupOpen && !isFeedbackReasonSubmitted && (
        <Stack style={{ border: "1px solid #22272B", borderRadius: "4px", height: "170px", marginLeft: "37px", marginRight: "71px" }}>
          <SectionPage>
            <Stack style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", paddingLeft: "1px" }}>
              <TextField
                style={{ fontSize: "18px", padding: "31px 96px 26px 26px", height: "160px", width: "700px", resize: "none" }}
                className={styles.questionInputTextArea}
                placeholder={"Please write your feedback"}
                multiline
                borderless
                value={textFieldValue}
                onChange={onInputChange}
                onKeyDown={onKeyChange}
                componentRef={inputRef}
              />
              <div className={styles.buttons}>
                <div onClick={() => onCloseFeedback()}>
                  <CloseInput />
                </div>
                <div onClick={submitFeedback}>
                  <SubmitFeedback />
                </div>
              </div>
            </Stack>
          </SectionPage>
        </Stack>
      )}
    </Stack.Item>
  );
};
