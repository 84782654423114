const IconMissingInfo = () => (
  <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.7"
      d="M7.6 21L5.7 17.8L2.1 17L2.45 13.3L0 10.5L2.45 7.7L2.1 4L5.7 3.2L7.6 0L11 1.45L14.4 0L16.3 3.2L19.9 4L19.55 7.7L22 10.5L19.55 13.3L19.9 17L16.3 17.8L14.4 21L11 19.55L7.6 21ZM8.45 18.45L11 17.35L13.6 18.45L15 16.05L17.75 15.4L17.5 12.6L19.35 10.5L17.5 8.35L17.75 5.55L15 4.95L13.55 2.55L11 3.65L8.4 2.55L7 4.95L4.25 5.55L4.5 8.35L2.65 10.5L4.5 12.6L4.25 15.45L7 16.05L8.45 18.45ZM11 15.5C11.2833 15.5 11.5208 15.4042 11.7125 15.2125C11.9042 15.0208 12 14.7833 12 14.5C12 14.2167 11.9042 13.9792 11.7125 13.7875C11.5208 13.5958 11.2833 13.5 11 13.5C10.7167 13.5 10.4792 13.5958 10.2875 13.7875C10.0958 13.9792 10 14.2167 10 14.5C10 14.7833 10.0958 15.0208 10.2875 15.2125C10.4792 15.4042 10.7167 15.5 11 15.5ZM10 11.5H12V5.5H10V11.5Z"
      fill="#22272B"
    />
  </svg>
);

export default IconMissingInfo;
