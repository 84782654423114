const IconExample = () => (
  <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.0834 28.5C10.6348 28.5 10.2588 28.3483 9.95529 28.0448C9.65182 27.7413 9.50008 27.3653 9.50008 26.9167V23.75H30.0834V9.49999H33.2501C33.6987 9.49999 34.0747 9.65173 34.3782 9.9552C34.6817 10.2587 34.8334 10.6347 34.8334 11.0833V34.8333L28.5001 28.5H11.0834ZM3.16675 26.9167V4.74999C3.16675 4.30138 3.31848 3.92534 3.62196 3.62186C3.92543 3.31839 4.30147 3.16666 4.75008 3.16666H25.3334C25.782 3.16666 26.1581 3.31839 26.4615 3.62186C26.765 3.92534 26.9167 4.30138 26.9167 4.74999V19C26.9167 19.4486 26.765 19.8246 26.4615 20.1281C26.1581 20.4316 25.782 20.5833 25.3334 20.5833H9.50008L3.16675 26.9167ZM23.7501 17.4167V6.33332H6.33342V17.4167H23.7501Z"
      fill="#22272B"
    />
  </svg>
);

export default IconExample;
