const ServiceNSWLogo = ({w, h}: {w: string, h: string}) => (
  <svg width={w} height={h} viewBox="0 0 229 82" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1060_1102)">
      <path d="M104.069 52.9398L117.215 71.9662H122.578V43.509H116.58V61.8062L103.998 43.556L103.951 43.509H98.095V71.9662H104.069V52.9398Z" fill="#002664" />
      <path
        d="M147.107 59.1722C146.59 58.3255 145.79 57.5494 144.732 56.8674C143.697 56.1853 142.262 55.6679 140.522 55.3387L135.654 54.3274C134.172 53.9981 133.161 53.5983 132.643 53.1044C132.126 52.6341 131.867 51.9756 131.867 51.1995C131.867 50.7056 131.985 50.2587 132.197 49.8589C132.408 49.4591 132.738 49.1063 133.161 48.8241C133.584 48.5184 134.102 48.2832 134.69 48.1186C135.301 47.9539 135.983 47.8599 136.736 47.8599C137.723 47.8599 138.594 48.001 139.346 48.2361C140.099 48.4948 140.71 48.9182 141.204 49.4826C141.675 50.0471 141.98 50.8232 142.074 51.7874L142.098 51.9285H148.142V51.7874C148.119 50.1646 147.648 48.6595 146.778 47.3424C145.884 46.0254 144.591 44.9671 142.897 44.191C141.204 43.4149 139.111 43.0151 136.642 43.0151C134.572 43.0151 132.69 43.3679 130.997 44.0969C129.327 44.8025 127.987 45.8373 126.999 47.1543C126.011 48.4713 125.541 50.0706 125.541 51.8815C125.588 54.0922 126.246 55.8796 127.54 57.1966C128.81 58.5137 130.55 59.3838 132.738 59.8072L137.653 60.8185C138.547 61.0066 139.37 61.2418 140.075 61.524C140.781 61.8062 141.322 62.159 141.722 62.6059C142.121 63.0292 142.31 63.5936 142.31 64.2757C142.31 65.0282 142.074 65.6632 141.58 66.1571C141.087 66.6745 140.405 67.0508 139.558 67.3095C138.711 67.5682 137.747 67.6858 136.736 67.6858C135.724 67.6858 134.807 67.5212 133.984 67.2155C133.161 66.9097 132.479 66.4629 131.961 65.8984C131.421 65.334 131.068 64.6284 130.88 63.7818L130.856 63.6642H124.694L124.718 63.8288C124.859 65.2634 125.306 66.5569 126.058 67.6388C126.787 68.7206 127.728 69.6143 128.857 70.3434C129.986 71.0489 131.232 71.5899 132.596 71.9662C133.96 72.3189 135.348 72.5071 136.759 72.5071C139.064 72.5071 141.11 72.1543 142.827 71.4252C144.567 70.6962 145.931 69.7084 146.896 68.4619C147.883 67.2154 148.377 65.7808 148.377 64.2286C148.377 63.4995 148.283 62.6764 148.119 61.7827C147.977 60.9125 147.625 60.0188 147.107 59.1722Z"
        fill="#002664"
      />
      <path
        d="M179.28 43.509L174.153 61.7122L168.956 43.509H164.628L159.501 61.7122L154.374 43.509H148.495L156.867 71.9662H161.548L166.792 53.9511L171.966 71.9662H176.74L185.136 43.509H179.28Z"
        fill="#002664"
      />
      <path
        d="M132.62 38.6641C130.597 38.6641 128.81 38.2408 127.281 37.3941C125.753 36.5475 124.553 35.348 123.706 33.7958C122.86 32.2436 122.437 30.4092 122.437 28.3161C122.437 26.27 122.836 24.4355 123.612 22.8128C124.389 21.1665 125.517 19.873 126.999 18.9322C128.481 17.9915 130.245 17.4976 132.338 17.4976C134.407 17.4976 136.148 17.9445 137.559 18.8146C138.97 19.6848 140.028 20.9078 140.757 22.46C141.486 24.0122 141.839 25.8231 141.839 27.8457V29.3979H128.151C128.128 30.9501 128.504 32.1966 129.28 33.1138C130.056 34.031 131.115 34.5014 132.502 34.5014C133.49 34.5014 134.337 34.2897 135.089 33.8664C135.818 33.4431 136.336 32.761 136.642 31.7968H141.816C141.58 33.2314 140.992 34.4779 140.075 35.4892C139.158 36.524 138.029 37.3001 136.712 37.841C135.395 38.4054 134.031 38.6641 132.62 38.6641ZM128.128 25.9877H136.43C136.43 24.7648 136.077 23.7065 135.395 22.8363C134.713 21.9661 133.678 21.5428 132.291 21.5428C131.42 21.5428 130.668 21.7544 130.033 22.2013C129.398 22.6246 128.928 23.1891 128.598 23.8711C128.269 24.5296 128.128 25.2352 128.128 25.9877Z"
        fill="#002664"
      />
      <path
        d="M144.661 38.2879V17.921H150.188V22.7422C150.682 21.19 151.458 19.92 152.564 18.9558C153.669 17.9915 155.033 17.5212 156.632 17.5212C157.055 17.5212 157.361 17.5682 157.526 17.6388V23.1185C157.408 23.0715 157.267 23.048 157.103 23.0245C156.938 23.001 156.797 22.9774 156.656 22.9774C154.374 22.7893 152.775 23.0715 151.811 23.8946C150.87 24.6943 150.376 25.7761 150.376 27.1402V38.2879H144.661Z"
        fill="#002664"
      />
      <path d="M166.769 38.0997L159.125 17.7328H164.323L168.768 30.715H168.932L173.33 17.7328H178.598L170.955 38.0997H166.769Z" fill="#002664" />
      <path d="M180.55 14.8166V9.64252H186.148V14.8166H180.55ZM180.621 38.0997V17.7328H186.124V38.0997H180.621Z" fill="#002664" />
      <path
        d="M198.471 38.476C196.59 38.476 194.897 38.0527 193.415 37.206C191.933 36.3594 190.757 35.1599 189.911 33.5842C189.064 32.0085 188.641 30.1505 188.641 27.9633C188.641 25.8702 189.04 24.0122 189.864 22.413C190.687 20.8137 191.816 19.5673 193.297 18.6501C194.779 17.7564 196.496 17.3095 198.471 17.3095C200.188 17.3095 201.646 17.6623 202.893 18.3443C204.139 19.0499 205.103 19.9906 205.833 21.1665C206.538 22.3424 206.961 23.6595 207.056 25.1176H202.258C202.117 24.2945 201.764 23.5184 201.176 22.7893C200.588 22.0367 199.694 21.6839 198.518 21.6839C197.178 21.6839 196.096 22.2013 195.249 23.2361C194.426 24.2709 194.003 25.8232 194.003 27.9398C194.003 29.9389 194.403 31.5146 195.226 32.667C196.049 33.8194 197.178 34.4073 198.636 34.4073C199.412 34.4073 200.047 34.2192 200.588 33.8429C201.105 33.4666 201.505 32.9962 201.811 32.4788C202.093 31.9379 202.281 31.444 202.352 30.9972H207.032C206.938 32.4083 206.538 33.6783 205.809 34.8072C205.08 35.936 204.092 36.8533 202.846 37.5118C201.576 38.1468 200.118 38.476 198.471 38.476Z"
        fill="#002664"
      />
      <path
        d="M219.309 38.476C217.286 38.476 215.499 38.0527 213.97 37.206C212.441 36.3594 211.242 35.1599 210.395 33.6077C209.548 32.0555 209.125 30.2211 209.125 28.1279C209.125 26.0819 209.525 24.2474 210.301 22.6247C211.077 20.9784 212.206 19.6849 213.688 18.7441C215.169 17.8034 216.933 17.3095 219.026 17.3095C221.096 17.3095 222.836 17.7564 224.247 18.6265C225.659 19.4967 226.717 20.7197 227.446 22.2719C228.175 23.8241 228.528 25.635 228.528 27.6576V29.2098H214.84C214.817 30.762 215.193 32.0085 215.969 32.9257C216.745 33.8429 217.803 34.3133 219.191 34.3133C220.179 34.3133 221.025 34.1016 221.778 33.6783C222.507 33.2549 223.024 32.5729 223.33 31.6087H228.504C228.269 33.0433 227.681 34.2898 226.764 35.301C225.847 36.3358 224.718 37.112 223.401 37.6529C222.06 38.2173 220.696 38.476 219.309 38.476ZM214.817 25.7996H223.119C223.119 24.5767 222.766 23.5184 222.084 22.6482C221.402 21.778 220.367 21.3547 218.979 21.3547C218.109 21.3547 217.357 21.5663 216.722 22.0132C216.087 22.4365 215.616 23.001 215.287 23.683C214.958 24.3415 214.793 25.047 214.817 25.7996Z"
        fill="#002664"
      />
      <path
        d="M119.05 25.4704C118.532 24.6237 117.733 23.8476 116.698 23.1891C115.663 22.507 114.252 22.0132 112.535 21.6604L107.69 20.6726C106.232 20.3669 105.221 19.9435 104.704 19.4497C104.186 18.9793 103.928 18.3443 103.928 17.5682C103.928 17.0743 104.045 16.6275 104.257 16.2277C104.468 15.8278 104.798 15.4751 105.198 15.1928C105.621 14.9106 106.115 14.6754 106.726 14.5108C107.338 14.3462 108.02 14.2521 108.749 14.2521C109.713 14.2521 110.583 14.3697 111.336 14.6284C112.088 14.8871 112.7 15.2869 113.17 15.8514C113.641 16.4158 113.923 17.1919 114.04 18.1326L114.064 18.2737H120.061V18.1326C120.038 16.5099 119.567 15.0282 118.697 13.7112C117.827 12.3942 116.533 11.3358 114.864 10.5832C113.194 9.80714 111.101 9.43085 108.678 9.43085C106.632 9.43085 104.751 9.78362 103.104 10.4892C101.435 11.1947 100.118 12.2295 99.1534 13.523C98.1891 14.8401 97.6952 16.4158 97.7187 18.2032C97.7657 20.3904 98.4243 22.1543 99.6943 23.4713C100.964 24.7883 102.681 25.6585 104.845 26.0583L109.713 27.0461C110.607 27.2342 111.406 27.4694 112.112 27.7516C112.794 28.0339 113.358 28.3866 113.758 28.8335C114.158 29.2568 114.346 29.8212 114.346 30.4798C114.346 31.2323 114.111 31.8673 113.617 32.3612C113.123 32.8786 112.441 33.2549 111.618 33.5136C110.771 33.7723 109.831 33.8899 108.819 33.8899C107.832 33.8899 106.914 33.7253 106.091 33.4196C105.292 33.1138 104.61 32.667 104.092 32.1025C103.575 31.5381 103.198 30.8325 103.01 30.0094L102.987 29.8918H96.8721L96.8956 30.0564C97.0367 31.4911 97.4835 32.761 98.2126 33.8194C98.9417 34.8777 99.8824 35.7949 100.988 36.5005C102.093 37.206 103.34 37.7469 104.704 38.0997C106.044 38.4525 107.432 38.6406 108.843 38.6406C111.124 38.6406 113.147 38.2879 114.864 37.5588C116.58 36.8532 117.945 35.8655 118.909 34.619C119.873 33.3725 120.367 31.9614 120.367 30.4327C120.367 29.7037 120.273 28.8805 120.132 28.0103C119.92 27.1872 119.591 26.2935 119.05 25.4704Z"
        fill="#002664"
      />
      <path
        d="M40.969 0C18.3443 0 0 18.3443 0 40.969C0 63.5936 18.3443 81.9379 40.969 81.9379C63.5936 81.9379 81.9379 63.5936 81.9379 40.969C81.9614 18.3443 63.6171 0 40.969 0Z"
        fill="#D7153A"
      />
      <path
        d="M48.3537 77.8692C55.174 76.5052 61.3123 73.3302 66.2512 68.8382C62.2766 67.1214 58.3725 65.6867 54.5155 64.5579C52.8457 69.3321 50.7761 73.777 48.3537 77.8692Z"
        fill="white"
      />
      <path
        d="M64.5108 64.5813C66.8627 61.2653 69.0969 57.6905 71.2135 53.857C71.6839 52.9868 71.6839 51.952 71.143 51.1053C70.6491 50.2822 69.7789 49.8118 68.8147 49.8118C68.7912 49.8118 68.7441 49.8118 68.7206 49.8118C65.0753 49.9294 61.5475 50.2116 58.1374 50.635C57.4553 54.3979 56.5851 58.0197 55.5268 61.4769C58.4901 62.3471 61.477 63.3819 64.5108 64.5813Z"
        fill="white"
      />
      <path
        d="M55.3622 34.5014C54.5626 33.9605 53.5513 33.8664 52.6576 34.2897C48.095 36.4299 43.8617 38.7347 39.9577 41.2041C40.9219 52.9868 39.9342 63.9228 37.0414 73.8241C36.5946 75.3527 36.0772 76.7874 35.5127 78.1749C37.3001 78.4336 39.111 78.5748 40.969 78.5748C42.0508 78.5748 43.1091 78.5277 44.1675 78.4337C51.3406 67.4035 55.5033 53.4572 56.5146 36.9003C56.6087 35.9595 56.1618 35.0423 55.3622 34.5014Z"
        fill="white"
      />
      <path
        d="M36.524 39.5814C36.0771 34.3838 35.0659 29.1157 34.5249 26.5287C34.3133 25.5174 33.6312 24.7648 32.667 24.4826C32.4083 24.412 32.1496 24.365 31.9144 24.365C31.2089 24.365 30.5268 24.6472 29.9859 25.1881C27.2578 27.9633 24.7178 30.762 22.366 33.6077C24.2004 38.0762 25.682 42.5212 26.8344 46.8956C29.7977 44.3321 33.0198 41.8862 36.524 39.5814Z"
        fill="white"
      />
      <path
        d="M24.1298 49.318C21.9426 40.428 18.5795 32.032 13.9464 23.2596C13.476 22.3424 12.5588 21.825 11.5475 21.825C10.5362 21.825 9.619 22.3659 9.14864 23.2596C7.19661 26.9285 5.47977 30.5738 3.9746 34.2192C3.57479 36.4064 3.36313 38.6641 3.36313 40.9689C3.36313 50.0235 6.56163 58.3255 11.8768 64.8165C15.1929 58.5136 20.2728 52.9163 24.1298 49.318Z"
        fill="white"
      />
      <path
        d="M36.8297 43.2737C29.8683 47.9304 19.191 57.1731 14.2051 67.38C19.0734 72.2954 25.2822 75.8937 32.2201 77.5635C32.4083 77.1166 32.5964 76.6463 32.7846 76.1759C36.9944 64.8401 37.4177 52.2107 36.8297 43.2737Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1060_1102">
        <rect width="228.528" height="81.9379" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ServiceNSWLogo;
