const SnswAvatar = () => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.9999 0C5.82066 0 0 5.82051 0 13.0002C0 20.18 5.82066 26 12.9999 26C20.1795 26 26 20.18 26 13.0002C26 5.82051 20.1795 0 12.9999 0Z"
      fill="#D7153A"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.064 15.8885C20.9157 15.6711 20.6936 15.5387 20.4503 15.5249C20.4463 15.5249 20.0855 15.5066 19.4742 15.5066C19.3717 15.5066 19.2622 15.5066 19.1448 15.5073C18.5965 15.5148 17.7148 15.5449 16.5984 15.6504C16.567 16.9305 16.4551 18.1845 16.2656 19.3824C17.1676 19.7247 18.0969 20.1342 19.034 20.5997C20.8664 18.2874 21.1461 16.7542 21.1881 16.3361C21.2015 16.1934 21.1238 15.976 21.064 15.8885Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.91796 13.8804C9.88073 13.741 9.81083 13.5676 9.68499 13.5676C9.63032 13.5676 9.56997 13.5935 9.50228 13.6447C9.50081 13.6461 9.43422 13.6953 9.31352 13.8004C9.19024 13.9 9.01432 14.0517 8.77181 14.2669C8.44711 14.5555 7.78617 15.1667 7.01791 16.0111C6.01208 17.1318 4.73899 18.7828 3.89258 20.7166C5.44506 22.5467 7.5376 23.9033 9.92512 24.5378C10.3153 23.1344 10.5158 21.5099 10.5158 19.6956C10.5158 17.2585 10.1621 15.1368 10.0104 14.3355C9.95172 14.0405 9.91796 13.8813 9.91796 13.8804Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.14333 10.3213C5.14333 10.3216 5.14351 10.3216 5.14351 10.3216C5.14149 10.3172 5.13966 10.3137 5.13746 10.3093C5.07398 10.1793 5.01143 10.0543 4.94924 9.93088C4.93603 9.9041 4.92228 9.87585 4.90888 9.84925C4.83899 9.71112 4.77075 9.57812 4.70343 9.44861C4.69811 9.4387 4.69297 9.42843 4.68802 9.41871C4.25142 8.58018 3.87481 7.93134 3.63542 7.53381C3.50096 7.32414 3.43253 7.21297 3.43253 7.21297C3.3054 7.02182 3.19864 7.00586 3.16819 7.00586C3.07904 7.00586 2.98603 7.07832 2.89339 7.21755C2.89137 7.21957 2.81653 7.32946 2.6927 7.53381C2.50761 7.84328 2.33132 8.14487 2.15191 8.45636C2.11999 8.51322 2.08569 8.57486 2.05083 8.63797C2.03377 8.6686 2.01653 8.6996 1.99892 8.73171C1.99323 8.74253 1.987 8.75409 1.98076 8.76473C1.85822 8.98871 1.7232 9.24095 1.57792 9.52162C1.24295 10.6227 1.06152 11.7905 1.06152 13.0012C1.06152 15.5995 1.89399 18.0023 3.30375 19.9622C4.13127 18.2226 5.36548 16.4912 6.98363 14.8092C6.42486 13.1129 5.75474 11.5731 5.14333 10.3213Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.6278 11.8575C15.6097 11.6662 15.5924 11.4974 15.5768 11.3501C15.5713 11.2993 15.566 11.2512 15.5611 11.2059C15.5579 11.1786 15.5552 11.1542 15.5523 11.1291C15.5499 11.1058 15.5471 11.0832 15.5445 11.0619C15.5416 11.0355 15.5385 11.0108 15.5357 10.9875C15.5341 10.9724 15.5326 10.9599 15.531 10.946C15.5249 10.8961 15.5198 10.8534 15.5154 10.8192C15.515 10.8159 15.5145 10.8123 15.5141 10.8093C15.5117 10.7901 15.5099 10.7756 15.508 10.7622C15.5073 10.7574 15.5069 10.7536 15.5062 10.7493C15.5033 10.7271 15.5013 10.7114 15.5011 10.7106C15.4477 10.4298 15.2997 10.2863 15.0478 10.2781C14.9992 10.2781 14.9216 10.2878 14.8227 10.3287L14.821 10.3302H14.8185C14.8163 10.3315 14.7002 10.3727 14.4886 10.4712C14.2823 10.5565 14.0073 10.6835 13.6608 10.8466L13.5399 10.9023C13.3319 11.003 13.1258 11.1072 12.9206 11.2129C12.0707 11.6475 11.2422 12.1175 10.4511 12.6163C10.3393 12.6794 10.2276 12.752 10.1172 12.8232C10.4202 12.9769 10.6539 13.2974 10.7409 13.6953C10.7429 13.7063 10.7447 13.7177 10.7492 13.736C10.8924 14.4146 11.3611 16.8531 11.3611 19.6965C11.3611 21.5711 11.1551 23.2575 10.751 24.7247C11.4785 24.8634 12.2286 24.9386 12.9969 24.9386C13.1702 24.9386 13.343 24.9329 13.5153 24.9256C13.5575 24.8553 13.6011 24.7874 13.6424 24.7153C15.3932 21.6476 15.7614 17.6463 15.7614 14.8321C15.7614 13.8341 15.7168 12.8351 15.6278 11.8575Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.1121 20.2368C15.7647 21.988 15.2336 23.5313 14.5332 24.8369C16.749 24.5516 18.7737 23.6601 20.4364 22.3321C19.9209 22.0302 19.3137 21.6934 18.6326 21.352C17.7835 20.9242 16.9374 20.5487 16.1121 20.2368Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.22904 10.629C6.69334 11.6285 7.11746 12.6432 7.48581 13.6543C8.39753 12.9367 9.39564 12.2541 10.528 11.5691C10.5152 11.5045 10.5024 11.4422 10.4897 11.3787C10.489 11.3759 10.4886 11.373 10.4879 11.3702C10.4692 11.2794 10.4508 11.1903 10.4319 11.1026C10.4314 11.0993 10.4306 11.0964 10.4303 11.0936C10.411 11.0026 10.3917 10.9133 10.3723 10.826C10.3719 10.8252 10.3719 10.8245 10.3717 10.8238C10.1353 9.75612 9.89736 8.93044 9.75703 8.47916C9.75482 8.47274 9.75299 8.46687 9.75079 8.46009C9.74162 8.43073 9.73244 8.40138 9.72401 8.37497C9.71373 8.34231 9.70401 8.31223 9.69521 8.28471C9.69044 8.2682 9.68713 8.25793 9.68493 8.24894C9.59651 8.01578 9.45159 7.89746 9.25384 7.89746C9.22541 7.89746 9.19881 7.89985 9.17074 7.90443H9.16652L9.16359 7.90517C9.09901 7.91085 9.03297 7.93819 8.96547 7.98643L8.96217 7.9879L8.95923 7.98955C8.95501 7.99212 8.49604 8.27389 7.78703 8.78111C7.39574 9.0627 6.77295 9.52755 6.01074 10.1595C6.0854 10.3154 6.1564 10.4723 6.22886 10.629H6.22904Z"
      fill="white"
    />
  </svg>
);

export default SnswAvatar;
