const IconLimitations = () => (
  <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.9999 25.3333C19.4485 25.3333 19.8246 25.1816 20.128 24.8781C20.4315 24.5746 20.5833 24.1986 20.5833 23.75C20.5833 23.3014 20.4315 22.9253 20.128 22.6219C19.8246 22.3184 19.4485 22.1667 18.9999 22.1667C18.5513 22.1667 18.1753 22.3184 17.8718 22.6219C17.5683 22.9253 17.4166 23.3014 17.4166 23.75C17.4166 24.1986 17.5683 24.5746 17.8718 24.8781C18.1753 25.1816 18.5513 25.3333 18.9999 25.3333ZM17.4166 19H20.5833V11.0833H17.4166V19ZM18.9999 34.8333C15.3319 33.9097 12.3037 31.8052 9.91554 28.5198C7.52735 25.2344 6.33325 21.5861 6.33325 17.575V7.91666L18.9999 3.16666L31.6666 7.91666V17.575C31.6666 21.5861 30.4725 25.2344 28.0843 28.5198C25.6961 31.8052 22.668 33.9097 18.9999 34.8333ZM18.9999 31.5083C21.7444 30.6375 24.0138 28.8958 25.8083 26.2833C27.6027 23.6708 28.4999 20.768 28.4999 17.575V10.0937L18.9999 6.53124L9.49992 10.0937V17.575C9.49992 20.768 10.3971 23.6708 12.1916 26.2833C13.986 28.8958 16.2555 30.6375 18.9999 31.5083Z"
      fill="#22272B"
    />
  </svg>
);

export default IconLimitations;
