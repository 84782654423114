import styles from "./UserChatMessage.module.css";
import Avatar from "../../assets/avatar";
import { SectionPage } from "@snsw-gel/react";

interface Props {
  message: string;
}

export const UserChatMessage = ({ message }: Props) => {
  return (
    <div className={styles.container}>
      <SectionPage className={styles.questionRoleChatUser}>
        <Avatar />
        <h4 className={styles.answerGPTTitle}>You</h4>
      </SectionPage>
      <div className={styles.message}>{message}</div>
    </div>
  );
};
