import { useEffect, useRef, useState } from "react";
import { Stack } from "@fluentui/react";
import DOMPurify from "dompurify";
import { SectionPage } from "@snsw-gel/react";
import styles from "./Answer.module.css";
import chatStyles from "../../pages/chat/Chat.module.css";
import { ChatAppResponse, FeedbackData, getCitationFilePath } from "../../api";
import { parseAnswerToHtml, Citation } from "./AnswerParser";
import SnswAvatar from "../../assets/snswAvatar";
import { RAGAgent } from "../../api/models"
import { Feedback } from "./Feedback";
import { marked } from "marked";

interface Props {
  answer: ChatAppResponse;
  isStreaming: boolean;
  showFeedback: boolean;
  selected_agent: RAGAgent;
  getFeedbackData: () => FeedbackData;
  onCitationClicked: (filePath: string, callback?: () => Promise<void>) => void;
  onFeedbackClicked: (sendFeedback: string) => Promise<void>;
}

interface ParsedAnswer {
  answerHtml: string;
  citations: Citation[];
}

export const Answer = ({ answer, isStreaming, selected_agent, getFeedbackData, onCitationClicked, onFeedbackClicked, showFeedback }: Props) => {
  const [parsedAnswer, setParsedAnswer] = useState<ParsedAnswer>({ answerHtml: "", citations: [] });
  const annotationContainersRef = useRef<NodeListOf<Element> | null>(null);

  useEffect(() => {
    const parseAndSanitize = async (): Promise<void> => {
      const parsedAnswerLocal = parseAnswerToHtml(answer.choices[0].message.content, isStreaming, selected_agent);
      const htmlContent = await marked(parsedAnswerLocal.answerHtml);
      parsedAnswerLocal.answerHtml = sanitizeAndSecureLinks(htmlContent);
      setParsedAnswer(parsedAnswerLocal);
    };

    parseAndSanitize();
  }, [answer, isStreaming, onCitationClicked]);

  useEffect(() => {
    const chatMsgBody = document.querySelector(`.${chatStyles.chatMessageBody}`);

    if (!chatMsgBody) {
      console.warn("Chat container not found!");
      return;
    }

    annotationContainersRef.current = document.querySelectorAll(".supContainer");

    const handleClick = (event: Event) => {
      event.preventDefault();
      const target = event.currentTarget as HTMLElement;
      const index = target.getAttribute("data-citation-index");
      if (index) {
        const citation = parsedAnswer.citations.find(citation => citation.id === index);
        if (citation) {
          chatMsgBody.scrollTo({
            top: target.offsetTop,
            behavior: "smooth"
          });
        }
      }
    };

    annotationContainersRef.current.forEach(annotationContainer => {
      annotationContainer.addEventListener("click", handleClick);
    });

    return () => {
      annotationContainersRef.current?.forEach(annotationContainer => {
        annotationContainer.removeEventListener("click", handleClick);
      });
    };
  }, [parsedAnswer.citations]);

  const sanitizeAndSecureLinks = (htmlContent: string): string => {
    // Sanitize HTML and allow 'target' attribute in anchor tags
    const sanitizedHtml = DOMPurify.sanitize(htmlContent, {
      ADD_ATTR: ["target"]
    });

    // temp doc
    const doc = new DOMParser().parseFromString(sanitizedHtml, "text/html");

    // Open all the links in a new tab
    doc.querySelectorAll<HTMLAnchorElement>("a").forEach(link => {
      link.target = "_blank";
      link.rel = "noopener noreferrer"; // For security purpose
    });

    return doc.body.innerHTML;
  };

  return (
    <SectionPage className={styles.answerContainer}>
      <Stack>
        <SectionPage className={styles.answerRoleChatGPT}>
          <SnswAvatar />
          <h4 className={styles.answerGPTTitle}>Sage</h4>
        </SectionPage>
      </Stack>

      <Stack.Item>
        <div className={styles.answerText} dangerouslySetInnerHTML={{ __html: parsedAnswer.answerHtml }}></div>
      </Stack.Item>

      {!isStreaming && parsedAnswer.citations.length > 0 && selected_agent !== RAGAgent.FairTrading && (
        <Stack.Item>
          <Stack horizontal wrap tokens={{ childrenGap: 5 }} style={{ marginLeft: "36px" }}>
            {parsedAnswer.citations.map((x, i) => {
              let cleanPart = x.text.replace(/^\(/, "").trimEnd(); // removes the first '('
              cleanPart = cleanPart.replace(/\)$/, ""); // removes the last ')'
              cleanPart = cleanPart.replace(":", "");

              if (cleanPart.includes(",")) {
                cleanPart.split(",");
              }

              // FIXME: KB files should be validated and fixed from the backend.
              if (cleanPart === "Changes to the residential tenancy laws.pdf") {
                cleanPart = "fairtrading.nsw.gov.au-Changes to the residential tenancy laws.pdf";
              }

              return (
                <a id={`${x.id}`} key={x.id} className={styles.citation} title={cleanPart} onClick={() => onCitationClicked(getCitationFilePath(cleanPart, selected_agent))}>
                  {`${i + 1}. ${cleanPart}`}
                </a>
              );
            })}
          </Stack>
        </Stack.Item>
      )}
      {!isStreaming && showFeedback && <Feedback getFeedbackData={getFeedbackData} save={onFeedbackClicked} />}
    </SectionPage>
  );
};
