import { useOktaAuth } from "@okta/okta-react";
import { useEffect } from "react";
import { Redirect } from "react-router-dom";
import Onboarding from "../pages/onboarding/Onboarding";
import session from "../session";

export const OktaLogin = () => {
  const { authState, oktaAuth } = useOktaAuth();

  useEffect(() => {
    if (authState?.isAuthenticated === false && !authState?.isPending) {
      oktaAuth.signInWithRedirect();
    }
  }, [authState, oktaAuth]);

  if (authState?.isPending) {
    return <div>Loading...</div>;
  }

  if (authState?.isAuthenticated) {
    session.start();
    return <Redirect to="/onboarding" />;
  }

  return <Onboarding />;
};
