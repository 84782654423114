const IconFeedback = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.7"
      d="M4 12H7.05L12.05 7C12.2 6.85 12.3125 6.67917 12.3875 6.4875C12.4625 6.29583 12.5 6.10833 12.5 5.925C12.5 5.74167 12.4583 5.5625 12.375 5.3875C12.2917 5.2125 12.1833 5.05 12.05 4.9L11.15 3.95C11 3.8 10.8333 3.6875 10.65 3.6125C10.4667 3.5375 10.275 3.5 10.075 3.5C9.89167 3.5 9.70417 3.5375 9.5125 3.6125C9.32083 3.6875 9.15 3.8 9 3.95L4 8.95V12ZM5.5 10.5V9.55L8.025 7.025L8.525 7.475L8.975 7.975L6.45 10.5H5.5ZM8.525 7.475L8.975 7.975L8.025 7.025L8.525 7.475ZM9.175 12H16V10H11.175L9.175 12ZM0 20V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H18C18.55 0 19.0208 0.195833 19.4125 0.5875C19.8042 0.979167 20 1.45 20 2V14C20 14.55 19.8042 15.0208 19.4125 15.4125C19.0208 15.8042 18.55 16 18 16H4L0 20ZM3.15 14H18V2H2V15.125L3.15 14Z"
      fill="#22272B"
    />
  </svg>
);

export default IconFeedback;
