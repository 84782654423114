import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import session from "../session";

export const OktaLogout = () => {
  const { oktaAuth } = useOktaAuth();
  const history = useHistory();
  useEffect(() => {
    const handleLogout = async () => {
      try {
        localStorage.removeItem("coai-conversation");
        session.clear();
        await oktaAuth.signOut();
        history.push("/");
      } catch (error) {
        console.error("Error during logout:", error);
      }
    };

    handleLogout();
  }, [history, oktaAuth]);

  return null;
};
