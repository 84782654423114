import { Stack } from "@fluentui/react";
import { animated, useSpring } from "@react-spring/web";
import styles from "./Answer.module.css";
import SnswAvatar from "../../assets/snswAvatar";
import { SectionPage } from "@snsw-gel/react";

export const AnswerLoading = () => {
  const animatedStyles = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 }
  });

  return (
    <animated.div style={{ ...animatedStyles }}>
      <Stack className={styles.answerContainer} verticalAlign="space-between">
        <SectionPage className={styles.answerRoleChatGPT}>
          <SnswAvatar />
          <h4 className={styles.answerGPTTitle}>Sage</h4>
        </SectionPage>
        <div>
          <p className={styles.answerText}>
            Generating answer
            <span className={styles.loadingdots} />
          </p>
        </div>
      </Stack>
    </animated.div>
  );
};
