const IconCapabilities = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.5 13.75C22.5 14.0815 22.3683 14.3995 22.1339 14.6339C21.8995 14.8683 21.5815 15 21.25 15C19.5924 15 18.0027 15.6585 16.8306 16.8306C15.6585 18.0027 15 19.5924 15 21.25C15 21.5815 14.8683 21.8995 14.6339 22.1339C14.3995 22.3683 14.0815 22.5 13.75 22.5C13.4185 22.5 13.1005 22.3683 12.8661 22.1339C12.6317 21.8995 12.5 21.5815 12.5 21.25C12.5 19.5924 11.8415 18.0027 10.6694 16.8306C9.49731 15.6585 7.9076 15 6.25 15C5.91848 15 5.60054 14.8683 5.36612 14.6339C5.1317 14.3995 5 14.0815 5 13.75C5 13.4185 5.1317 13.1005 5.36612 12.8661C5.60054 12.6317 5.91848 12.5 6.25 12.5C7.9076 12.5 9.49731 11.8415 10.6694 10.6694C11.8415 9.49731 12.5 7.9076 12.5 6.25C12.5 5.91848 12.6317 5.60054 12.8661 5.36612C13.1005 5.1317 13.4185 5 13.75 5C14.0815 5 14.3995 5.1317 14.6339 5.36612C14.8683 5.60054 15 5.91848 15 6.25C15 7.9076 15.6585 9.49731 16.8306 10.6694C18.0027 11.8415 19.5924 12.5 21.25 12.5C21.5815 12.5 21.8995 12.6317 22.1339 12.8661C22.3683 13.1005 22.5 13.4185 22.5 13.75Z"
      fill="#22272B"
    />
    <path
      d="M23.75 30C23.75 30.3315 23.6183 30.6495 23.3839 30.8839C23.1495 31.1183 22.8315 31.25 22.5 31.25C21.837 31.25 21.2011 31.5134 20.7322 31.9822C20.2634 32.4511 20 33.087 20 33.75C20 34.0815 19.8683 34.3995 19.6339 34.6339C19.3995 34.8683 19.0815 35 18.75 35C18.4185 35 18.1005 34.8683 17.8661 34.6339C17.6317 34.3995 17.5 34.0815 17.5 33.75C17.5 33.087 17.2366 32.4511 16.7678 31.9822C16.2989 31.5134 15.663 31.25 15 31.25C14.6685 31.25 14.3505 31.1183 14.1161 30.8839C13.8817 30.6495 13.75 30.3315 13.75 30C13.75 29.6685 13.8817 29.3505 14.1161 29.1161C14.3505 28.8817 14.6685 28.75 15 28.75C15.663 28.75 16.2989 28.4866 16.7678 28.0178C17.2366 27.5489 17.5 26.913 17.5 26.25C17.5 25.9185 17.6317 25.6005 17.8661 25.3661C18.1005 25.1317 18.4185 25 18.75 25C19.0815 25 19.3995 25.1317 19.6339 25.3661C19.8683 25.6005 20 25.9185 20 26.25C20 26.913 20.2634 27.5489 20.7322 28.0178C21.2011 28.4866 21.837 28.75 22.5 28.75C22.8315 28.75 23.1495 28.8817 23.3839 29.1161C23.6183 29.3505 23.75 29.6685 23.75 30Z"
      fill="#22272B"
    />
    <path
      d="M35 21.25C35 21.5815 34.8683 21.8995 34.6339 22.1339C34.3995 22.3683 34.0815 22.5 33.75 22.5C32.4239 22.5 31.1521 23.0268 30.2145 23.9645C29.2768 24.9021 28.75 26.1739 28.75 27.5C28.75 27.8315 28.6183 28.1495 28.3839 28.3839C28.1495 28.6183 27.8315 28.75 27.5 28.75C27.1685 28.75 26.8505 28.6183 26.6161 28.3839C26.3817 28.1495 26.25 27.8315 26.25 27.5C26.25 26.1739 25.7232 24.9021 24.7855 23.9645C23.8479 23.0268 22.5761 22.5 21.25 22.5C20.9185 22.5 20.6005 22.3683 20.3661 22.1339C20.1317 21.8995 20 21.5815 20 21.25C20 20.9185 20.1317 20.6005 20.3661 20.3661C20.6005 20.1317 20.9185 20 21.25 20C22.5761 20 23.8479 19.4732 24.7855 18.5355C25.7232 17.5979 26.25 16.3261 26.25 15C26.25 14.6685 26.3817 14.3505 26.6161 14.1161C26.8505 13.8817 27.1685 13.75 27.5 13.75C27.8315 13.75 28.1495 13.8817 28.3839 14.1161C28.6183 14.3505 28.75 14.6685 28.75 15C28.75 16.3261 29.2768 17.5979 30.2145 18.5355C31.1521 19.4732 32.4239 20 33.75 20C34.0815 20 34.3995 20.1317 34.6339 20.3661C34.8683 20.6005 35 20.9185 35 21.25Z"
      fill="#22272B"
    />
  </svg>
);

export default IconCapabilities;
