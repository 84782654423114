import { Row, Col, ContentContainer, SectionPage, Button } from "@snsw-gel/react";
import { Example } from "./Example";
import styles from "./Example.module.css";

export type QuestionsModel = {
  text: string;
};

const asValue = (s: string) => ({ text: s, title: s });

const questionsColOne: QuestionsModel[] = [
  asValue("How often can rent be increased?"),
  asValue("How can a tenant claim back their bond?")
];

const questionsColTwo: QuestionsModel[] = [
  asValue("Can a tenant break a lease penalty free?"),
  asValue("Who is responsible for removing mould?")
];

interface Props {
  onExampleClicked: (value: string) => void;
}

export const ExampleList = ({ onExampleClicked }: Props) => {
    return (
      <SectionPage className={styles.examplesNavList}>
        <Row>
          <Col className={styles.examplesColLeft} span={6}>
            {questionsColOne.map((x, i) => (
              <Example text={x.text} value={x.text} onClick={onExampleClicked} />
            ))}
          </Col>
          <Col className={styles.examplesColRight} span={6}>
            {questionsColTwo.map((x, i) => (
              <Example text={x.text} value={x.text} onClick={onExampleClicked} />
            ))}
          </Col>
        </Row>
    </SectionPage>
  );
};
