import { useHistory } from "react-router-dom";
import NswIcon from "../../assets/nswIcon";
import ServiceNSWLogo from "../../assets/serviceNSWLogo";
import { Row, Col, ContentContainer, SectionPage, Button } from "@snsw-gel/react";
import IconExample from "../../assets/iconExample";
import IconCapabilities from "../../assets/iconCapabilities";
import IconLimitations from "../../assets/iconLimitations";
import styled from "styled-components";

const ButtonCSS = styled(Button)`
  display: flex;
  width: 300px;
  height: 50px;
  min-width: 202px;
  background-color: #002664 !important;
  padding: 16px 24px;
  margin: auto;
  margin-top: 15px;
  border: none !important;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    color: #002664;
    background-color: white !important;
    border: 3px solid #002664 !important;
  }
`;

const TopSection = styled(SectionPage)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;

  h4 {
    font-size: 21px;
    font-weight: 500;
    text-align: center;
    width: 50%;
  }
`;

const Section = styled(SectionPage)`
  display: flex;
  font-size: 21px;
  font-style: normal;
  font-weight: 500 !important;
  flex-direction: column;
  align-items: center;
  margin-bottom: 72px;
`;

const RowStyle = styled(Row)`
  width: 91%;
`;

const Column = styled(Col)`
  text-align: center;

  h4 {
    text-align: center;
    margin-top: 0px;
  }

  p {
    padding: 5px;
    text-align: left;
  }
`;

const Wrapper = styled.div`
  background-color: #f4f7f9;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin: auto;
  padding: 0px 8px 0px 3px;
  height: 50px;
  width: 300px;
  border-radius: 4px;
`;

const Onboarding = () => {
  const history = useHistory();

  const home = () => {
    history.push("/home");
  };

  return (
    <ContentContainer>
      <TopSection>
        <ServiceNSWLogo w={"177"} h={"61"} />
        <h4>I’m Sage, a Bonds and Tenancy AI agent.</h4>
      </TopSection>
      <Section>
        <RowStyle>
          <Column span={4}>
            <IconExample />
            <h4>Examples</h4>
            <Wrapper>
              <p>How often can a landlord increase the rent?</p>
            </Wrapper>
            <Wrapper>
              <p>How can a tenant claim back their bond?</p>
            </Wrapper>
            <Wrapper>
              <p>Can a tenant terminate a lease early without penalty?</p>
            </Wrapper>
          </Column>
          <Column span={4}>
            <IconCapabilities />
            <h4>Capabilities</h4>
            <Wrapper>
              <p>I can find and summarise information for you.</p>
            </Wrapper>
            <Wrapper>
              <p>Provide me with context and I’ll remember it through our conversation.</p>
            </Wrapper>
            <Wrapper>
              <p>I’m eager to learn. You can leave feedback to help me improve.</p>
            </Wrapper>
          </Column>
          <Column span={4}>
            <IconLimitations />
            <h4>Limitations</h4>
            <Wrapper>
              <p>I’m in development and still learning. Please verify the information I provide. </p>
            </Wrapper>
            <Wrapper>
              <p>I only know the Residential Tenancies Act 2010, NSW Fair Trading and KAs.</p>
            </Wrapper>
            <Wrapper>
              <p>I cannot explain Legislation, only retrieve relevant section(s).</p>
            </Wrapper>
          </Column>
        </RowStyle>
      </Section>
      <ButtonCSS onClick={home}>Got it, thanks</ButtonCSS>
    </ContentContainer>
  );
};

export default Onboarding;
